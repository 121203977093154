import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Flex, TitleHover } from "../elements"
import website from "../../config/website"

const GridWithHero = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 5px 5px; 
  margin-top: 18px;  
  ${props => props.theme.mediaQueries.sm} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${props => props.theme.mediaQueries.md} {
    grid-template-columns: repeat(5, 1fr);
  }
  ${props => props.theme.mediaQueries.lg} {
       grid-template-columns: repeat(6, 1fr);
  }
  ${props => props.theme.mediaQueries.xl} {
       grid-template-columns: repeat(auto-fit, minmax(100px, 300px));    
  }
  &:before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }     
  & > *:first-child {
    grid-gap: 0; 
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }   
`

const StyledLink = styled(Link)`
  &:hover > ${TitleHover} {
    display: flex;
  }
`

const Client = ({
  data: { prismicClient: client,  allPrismicProject: projects},
  location,
  pageContext: { breadcrumb },
}) => {  
  const desc = client.data.meta_description || client.data.name
  const title = client.data.meta_title || client.data.name
  return (
    <Layout customSEO location={location} breadcrumb={breadcrumb}>
      <SEO
        title={`${title} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={desc}
        node={client}
      />
      <GridWithHero>
        {projects.edges.map(
          // eslint-disable-next-line camelcase
          (
            {
              node: { 
                data: {
                  image_mosaic,                
                  title,                
                  canonical,
                }, 
                uid
              }
            },
            index
          ) => {
            // eslint-disable-next-line camelcase
            if (canonical) {
              if (image_mosaic)
                return (
                  <StyledLink
                    to={`${canonical}/${uid}`}
                    key={uid}
                    css="position:relative;"
                  >
                    <Img
                      fluid={image_mosaic.fluid}
                      height="100%"
                      width="100%"
                      alt={image_mosaic.alt}
                    />
                    <TitleHover>{title.text}</TitleHover>
                  </StyledLink>
                )
              /* if (__typename === 'PRISMIC__ExternalLink') {
            return (
              <StyledLinkAhref href={url} key={`${index}-img`} css="position:relative;">
                <Img
                  // src={media.image_mosaic.url}
                  fluid={imageSharp.childImageSharp.fluid}
                  height="100%"
                  width="100%"
                  alt={image.alt}
                />
                <TitleHover>{RichText.asText(title_item)}</TitleHover>
              </StyledLinkAhref>
            );
          }
          if (media.__typename === 'PRISMIC__FileLink') {
            return (
              <StyledLinkAhref href={media.url} key={`${index}-img`} css="position:relative;">
                <Img
                  // src={media.image_mosaic.url}
                  fluid={imageSharp.childImageSharp.fluid}
                  height="100%"
                  width="100%"
                  alt={image.alt}
                />
                <TitleHover>{RichText.asText(title_item)}</TitleHover>
              </StyledLinkAhref>
            );
          } */
              if (title)
                return (
                  <Flex
                    bg="white"
                    color="black"
                    alignItems="center"
                    justifyContent="center"
                    key={uid}
                  >
                    <Link to={`${canonical}/${uid}`}>
                      {title.text}
                    </Link>
                  </Flex>
                )
            }
            return null;
          }
        )}
      </GridWithHero>
    </Layout>
  )
}

export default Client

Client.propTypes = {
  data: PropTypes.shape({
    prismicClient: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query ClientBySlug( $uid: String!) {
    prismicClient(uid: { eq: $uid }, lang: { eq: "en-us" }) {
      data {
        name
        meta_title
        meta_description
        canonical
      }
    }
    allPrismicProject(
        filter: {
          lang: { eq: "en-us" }
          data: { client: { uid: { eq: $uid } } }
        }
      ) {
        edges {
          node {
            data {
              __typename
              title {
                text
              }
              meta_title
              meta_description
              canonical
              image_mosaic {
                url
                alt
                fluid(maxWidth: 600, maxHeight: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            uid
          }
        }
      }
    
    
      
  }
`
